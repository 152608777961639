/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

/*================================================
Default CSS
=================================================*/
:root {
    --fontFamily: 'Open Sans', sans-serif;
    --headingFontFamily: 'Inter', sans-serif;
    --mainColor: #c44118;
    --optionalColor: #666666;
    --whiteColor: #ffffff;
    --blackColor: #0e0129;
    --fontSize: 15px;
    --transition: .5s;
}

body {
    margin: 0;
    padding: 0;
    color: var(--blackColor);
    background-color: #f1f1f1;

    font: {
        size: var(--fontSize);
        family: var(--fontFamily);
    }

    ;
}
// .h1{
//     font-size: MyFont;
//     margin: 0;
// }
@font-face {
    font-family:"Congenial_Black" ;
    // src: url("./assets/fonts/fonnts.com-Congenial_Regular.otf") format("truetype");
    font-style: normal;
    font-weight: normal;
    src: url("./assets/fonts/fonnts.com-Congenial_Black.otf") format("truetype");
}
// 
.normal {
    /* color: rgb(12, 0, 40); */
    color:  rgb(0, 0, 0);
    font-family: Congenial_Black;
    margin: 0; 
  }
  .specialCharacter {
    // color: rgb(143, 214, 116);
    color: rgb(125 203 95);
    margin-left: 5px;
    font-family: Congenial_Black;
    margin: 0;
}
.o {
    /* color: rgb(254, 165, 0); */
    //color:rgb(243 129 10);
    color:#ff9e1e;
    margin-left: 5px;
    font-family: Congenial_Black;
}
.h1,
div{
    font-size: MyFont;
    margin: 0; 
}
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--blackColor);

    font: {
        weight: 700;
        family: var(--headingFontFamily);
    }

    ;
}

a {
    color: var(--blackColor);
    transition: var(--transition);
    text-decoration: none;
    outline: 0 !important;

    &:hover {
        color: var(--mainColor);
        text-decoration: none;
    }
}

:focus {
    outline: 0 !important;
}

.d-table {
    width: 100%;
    height: 100%;

    &-cell {
        vertical-align: middle;
    }
}

img {
    max-width: 100%;
    height: auto;
}

p {
    color: var(--optionalColor);
    font-size: var(--fontSize);
    margin-bottom: 15px;
    line-height: 1.8;

    &:last-child {
        margin-bottom: 0;
    }
}

.ptb-100 {
    padding: {
        top: 100px;
        bottom: 100px;
    }

    ;
}

.pt-100 {
    padding-top: 100px;
}

.pb-100 {
    padding-bottom: 100px;
}

.ptb-70 {
    padding: {
        top: 70px;
        bottom: 70px;
    }

    ;
}

.pt-70 {
    padding-top: 70px;
}

.pb-70 {
    padding-bottom: 70px;
}

.justify-content-center {
    justify-content: center;
}

.container {
    max-width: 1230px;
}

.bg-ffffff {
    background-color: var(--whiteColor);
}

/*section-title*/
.section-title {
    max-width: 720px;
    text-align: center;
    position: relative;

    margin: {
        left: auto;
        right: auto;
        bottom: 40px;
    }

    ;

    h2 {
        font-size: 26px;
        margin-bottom: 10px;
    }

    p {
        color: var(--blackColor);
    }

    &.text-start {
        max-width: 100%;

        margin: {
            left: 0;
            right: 0;
        }

        ;

        p {
            max-width: 720px;
        }
    }
}

.default-custom-btn {
    border: none;
    padding: 10px 0px;
    display: inline-block;
    transition: var(--transition);
    box-shadow: unset !important;
    background-color: var(--mainColor);
    color: var(--whiteColor);

    font: {
        weight: 600;
        size: var(--fontSize);
        family: var(--headingFontFamily)
    }

    ;

    &:hover {
        color: var(--whiteColor);
        background-color: var(--blackColor);
    }
}

.default-custom-lock-btn {
    border: none;
    padding: 10px 0px;
    display: inline-block;
    transition: var(--transition);
    box-shadow: unset !important;
    color: var(--whiteColor);
    background-color: var(--blackColor);

    font: {
        weight: 600;
        size: var(--fontSize);
        family: var(--headingFontFamily)
    }

    ;
}

.default-custom-btn i {
    font-size: 20px;
}

.default-custom-lock-btn i {
    font-size: 20px;
}

/*default-btn*/
.default-btn {
    border: none;
    padding: 12px 40px;
    display: inline-block;
    transition: var(--transition);
    box-shadow: unset !important;
    background-color: var(--mainColor);
    color: var(--whiteColor);

    font: {
        weight: 600;
        size: var(--fontSize);
        family: var(--headingFontFamily)
    }

    ;

    &:hover {
        color: var(--whiteColor);
        background-color: var(--blackColor);
    }
}

/*form-control*/
.form-control {
    border-radius: 0;
    background-color: var(--whiteColor) !important;
    box-shadow: unset !important;
    transition: var(--transition);
    border: none !important;
    height: 50px;
    padding-left: 15px;
    color: var(--blackColor);

    font: {
        size: var(--fontSize);
        weight: 500;
    }

    ;

    &::placeholder {
        color: var(--optionalColor);
        transition: var(--transition);
    }

    &:focus {
        &::placeholder {
            color: transparent;
        }
    }
}

textarea.form-control {
    padding-top: 15px;
    height: auto;
}

/*================================================
Extra CSS
=================================================*/
.deals-area {
    .container-fluid {
        padding: {
            left: 30px;
            right: 30px;
        }

        ;
    }

    &.bg-black {
        z-index: 1;
        position: relative;
        background-color: var(--blackColor);

        .section-title {
            h2 {
                color: var(--whiteColor);
            }

            p {
                color: var(--whiteColor);
            }
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 219px;
            background-color: #f1f1f1;
            z-index: -1;
        }
    }
}

.deals-slides {
    .owl-theme {
        .owl-nav {
            right: 0;
            top: -45px !important;
            margin-top: 0;
            position: absolute;

            [class*=owl-] {
                margin: 0;
                padding: 0;
                width: 40px;
                height: 40px;
                font-size: 27px;
                position: relative;
                border-radius: 50%;
                color: var(--blackColor);
                background: var(--whiteColor);
                transition: var(--transition);

                i {
                    left: 0;
                    right: 0;
                    top: 50%;
                    position: absolute;
                    transform: translateY(-50%);
                }

                &:hover {
                    color: var(--whiteColor);
                    background-color: var(--mainColor);
                }

                &.owl-next {
                    margin-left: 12px;
                }
            }
        }
    }
}

.deals-tabs {
    border-radius: 10px;
    background-color: var(--whiteColor);

    .nav-tabset {
        padding-left: 0;
        margin-bottom: 30px;
        list-style-type: none;

        .nav-tab {
            display: inline-block;
            margin-right: 10px;

            span {
                cursor: pointer;
                border-radius: 0;
                padding: 12px 50px;
                display: inline-block;
                color: var(--blackColor);
                background-color: #e4e4e4;
                transition: var(--transition);

                font: {
                    size: 16px;
                    weight: 700;
                    family: var(--headingFontFamily);
                }

                ;

                &:hover {
                    color: var(--whiteColor);
                    background-color: var(--mainColor);
                }
            }

            &.active {
                span {
                    color: var(--whiteColor);
                    background-color: var(--mainColor);
                }
            }

            &:nth-child(1) {
                span {
                    border-radius: 10px 0 0 0;
                }
            }
        }
    }

    .tabs-container {
        .pane {
            .single-deals-box {
                border-radius: 0;

                .deals-image {
                    border-radius: 0;

                    a {
                        border-radius: 0;

                        img {
                            border-radius: 0;
                        }
                    }
                }

                .box-footer {
                    padding-bottom: 0;
                }
            }
        }
    }
}

.deals-details-image-slides {
    .owl-theme {
        .owl-nav {
            opacity: 0;
            margin-top: 0;
            visibility: hidden;
            transition: var(--transition);

            [class*=owl-] {
                top: 50%;
                margin: 0;
                padding: 0;
                left: 20px;
                width: 45px;
                height: 45px;
                font-size: 22px;
                border-radius: 50%;
                position: absolute;
                color: var(--blackColor);
                transform: translateY(-50%);
                transition: var(--transition);
                padding-right: 2px !important;
                background: var(--whiteColor);

                i {
                    left: 0;
                    right: 0;
                    top: 50%;
                    margin-top: -1px;
                    position: absolute;
                    transform: translateY(-50%);
                }

                &.owl-next {
                    left: auto;
                    right: 20px;
                }

                &:hover {
                    color: var(--whiteColor);
                    border-color: var(--mainColor);
                    background-color: var(--mainColor);
                }
            }
        }

        &:hover {
            .owl-nav {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.page-title-area {
    text-align: center;
    position: relative;
    z-index: 1;

    padding: {
        top: 85px;
        bottom: 80px;
    }

    ;

    background: {
        size: cover;
        repeat: no-repeat;
        color: var(--mainColor);
        position: center center;
    }

    ;

    h1 {
        font-size: 35px;
        margin-bottom: 0;
        color: var(--whiteColor);
    }

    p {
        color: var(--whiteColor);
        margin-top: 12px;
        opacity: 0.95;
    }

    &::before {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        z-index: -1;
        opacity: 0.6;
        position: absolute;
        background-color: #000000;
    }
}

.faq-accordion {
    .panel-group {
        accordion-group {
            display: block;
            margin-bottom: 15px;
            background-color: var(--whiteColor);
            box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.05) !important;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .panel-heading {
            .panel-title {
                margin-bottom: 0;

                a {
                    display: block;
                    position: relative;

                    font: {
                        size: 17px;
                        weight: 700;
                    }

                    ;

                    padding: {
                        top: 20px;
                        left: 20px;
                        right: 60px;
                        bottom: 20px;
                    }

                    ;

                    &::before {
                        top: 50%;
                        right: 20px;
                        content: "\ea4a";
                        position: absolute;
                        transition: var(--transition);
                        transform: translateY(-50%) rotate(0);

                        font: {
                            family: 'boxicons';
                            size: 20px;
                        }

                        ;
                    }
                }
            }
        }

        .dropup {
            .panel-heading {
                .panel-title {
                    a {
                        &::before {
                            transform: translateY(-50%) rotate(180deg);
                        }
                    }
                }
            }
        }

        .panel-collapse {
            border-top: 1px solid #eeeeee;
            padding: 20px;

            .panel-body {
                ul {
                    margin-bottom: 15px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    li {
                        margin-bottom: 12px;
                        color: var(--optionalColor);

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        .collapse {
            &.in {
                display: block;
            }
        }
    }
}

.widget-area {
    .widget {
        padding: 30px;
        margin-bottom: 30px;
        background-color: var(--whiteColor);

        &:last-child {
            margin-bottom: 0;
        }

        .widget-title {
            font-size: 20px;
            margin-bottom: 25px;
            padding-bottom: 10px;
            border-bottom: 1px solid #e6e5e9;
        }
    }

    .widget_search {
        form {
            position: relative;

            label {
                display: block;
                margin-bottom: 0;
            }

            .screen-reader-text {
                display: none;
            }

            .search-field {
                height: 50px;
                display: block;
                width: 100%;
                border: none;
                border-radius: 5px;
                padding: 2px 0 0 15px;
                color: var(--blackColor);
                background-color: #f2f4f5;
                transition: var(--transition);

                font: {
                    size: var(--fontSize);
                    weight: 500;
                }

                ;

                &::placeholder {
                    transition: var(--transition);
                    color: var(--optionalColor);
                }

                &:focus {
                    &::placeholder {
                        color: transparent;
                    }
                }
            }

            button {
                top: 5px;
                right: 5px;
                padding: 0;
                width: 40px;
                border: none;
                height: 40px;
                font-size: 20px;
                position: absolute;
                color: var(--mainColor);
                transition: var(--transition);
                background-color: var(--whiteColor);

                i {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &:hover {
                    border-radius: 5px;
                    background-color: var(--mainColor);
                    color: var(--whiteColor);
                }
            }
        }
    }

    .widget_marxa_posts_thumb {
        position: relative;
        overflow: hidden;

        .item {
            margin-bottom: 20px;
            position: relative;
            padding-left: 95px;

            .thumb {
                top: 0;
                left: 0;
                width: 80px;
                float: unset;
                height: 100%;
                display: block;
                position: absolute;

                .fullimage {
                    width: 80px;
                    height: 100%;
                    display: inline-block;

                    background: {
                        size: cover !important;
                        repeat: no-repeat;
                        position: center center !important;
                    }

                    ;

                    &.bg1 {
                        background-image: url(assets/img/blog/blog1.jpg);
                    }

                    &.bg2 {
                        background-image: url(assets/img/blog/blog2.jpg);
                    }

                    &.bg3 {
                        background-image: url(assets/img/blog/blog3.jpg);
                    }

                    &.bg4 {
                        background-image: url(assets/img/blog/blog4.jpg);
                    }
                }

                &::before,
                &::after {
                    content: '';
                    z-index: 1;
                    top: 50%;
                    left: 50%;
                    opacity: 0;
                    position: absolute;
                    background-color: var(--whiteColor);
                    transform: translate(-50%, -50%);
                    transition: all .8s cubic-bezier(.2, 1, .22, 1);
                }

                &::before {
                    width: 40px;
                    height: 1px;
                    left: 100%;
                }

                &::after {
                    height: 40px;
                    width: 1px;
                    top: 0;
                }
            }

            .info {
                .title {
                    margin-bottom: 8px;
                    line-height: 1.4;

                    font: {
                        size: 15px;
                        weight: 600;
                    }

                    ;

                    a {
                        display: inline-block;
                    }
                }

                .meta {
                    padding-left: 0;
                    margin-bottom: 0;
                    list-style-type: none;

                    li {
                        font-size: 14px;
                        margin-right: 15px;
                        position: relative;
                        padding-left: 20px;
                        display: inline-block;
                        color: var(--optionalColor);

                        i {
                            left: 0;
                            top: 4px;
                            position: absolute;
                            color: var(--mainColor);
                        }

                        a {
                            display: inline-block;
                            color: var(--optionalColor);

                            &:hover {
                                color: var(--mainColor);
                            }
                        }

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }

            &:last-child {
                margin-bottom: 0;
            }

            &:hover {
                .thumb {

                    &::before,
                    &::after {
                        opacity: 1;
                        top: 50%;
                        left: 50%;
                    }
                }
            }
        }
    }

    .widget_categories {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                display: block;
                margin-bottom: 13.3px;

                a {
                    display: inline-block;
                    position: relative;
                    color: var(--optionalColor);
                    display: flex;
                    align-items: center;

                    font: {
                        family: var(--headingFontFamily);
                        weight: 500;
                        size: 15px;
                    }

                    ;

                    i {
                        width: 28px;
                        height: 28px;
                        line-height: 30px;
                        text-align: center;
                        border-radius: 3px;
                        color: var(--mainColor);
                        background-color: #f1f1f1;
                        transition: var(--transition);
                        margin-right: 10px;
                    }

                    &:hover {
                        color: var(--blackColor);

                        i {
                            background-color: var(--mainColor);
                            color: var(--whiteColor);
                        }
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .widget_custom_ads {
        padding: 0 !important;
    }

    .widget_socials_link {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                margin-bottom: 15px;

                a {
                    display: block;
                    background-color: #f5f5f5;
                    border-radius: 0 0 15px 15px;
                    position: relative;
                    color: var(--blackColor);

                    padding: {
                        top: 15px;
                        bottom: 12px;
                        right: 15px;
                        left: 90px;
                    }

                    ;

                    font: {
                        size: 15px;
                        weight: 600;
                    }

                    ;

                    i {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 70px;
                        background-color: var(--mainColor);
                        color: var(--whiteColor);
                        border-radius: 0 0 0 15px;
                        font-size: 22px;
                        text-align: center;
                        transition: var(--transition);

                        &::before {
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        }

                        &.bxl-facebook {
                            background-color: #485fb3;
                        }

                        &.bxl-twitter {
                            background-color: #00afe1;
                        }

                        &.bxl-linkedin {
                            background-color: #0e76a8;
                        }
                    }

                    &:hover {
                        border-radius: 0;

                        i {
                            border-radius: 0;
                        }
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .tagcloud {
        margin-top: -8px;

        a {
            display: inline-block;
            background: #f5f5f5;
            color: var(--blackColor);
            padding: 7px 15px 6px;
            border: none;
            border-radius: 3px;

            font: {
                weight: 600;
                size: 14px !important;
            }

            ;

            margin: {
                top: 8px;
                right: 4px;
            }

            ;

            &:hover {
                color: var(--whiteColor);
                background-color: var(--mainColor);
            }
        }
    }

    .widget_stores {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                margin-bottom: 13px;
                position: relative;
                padding-left: 19px;
                color: var(--optionalColor);

                font: {
                    size: var(--fontSize);
                    weight: 600;
                }

                ;

                input {
                    position: absolute;
                    top: 5px;
                    left: 0;
                }

                label {
                    display: block;
                    margin-bottom: 0;
                    overflow: hidden;
                    cursor: pointer;

                    .count {
                        float: right;
                        font-weight: normal;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .widget_looking_for {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                margin-bottom: 13px;
                position: relative;
                padding-left: 19px;
                color: var(--optionalColor);

                font: {
                    size: var(--fontSize);
                    weight: 600;
                }

                ;

                input {
                    position: absolute;
                    top: 5px;
                    left: 0;
                    cursor: pointer;
                }

                label {
                    cursor: pointer;
                    display: block;
                    margin-bottom: 0;
                    overflow: hidden;

                    .count {
                        float: right;
                        font-weight: normal;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .widget_about_store {
        .content {
            text-align: center;

            img {
                margin-bottom: 15px;
            }

            p {
                font-size: 14px;
            }

            .default-btn {
                margin-top: 5px;

                padding: {
                    left: 25px;
                    right: 25px;
                }

                ;
            }
        }
    }

    .widget_maps {
        iframe {
            margin-top: 5px;
            border: none;
            width: 100%;
            height: 250px;
        }
    }

    .widget_deals_sidebar {
        padding: 30px 0;
        text-align: center;

        .price {
            padding-bottom: 20px;
            border-bottom: 1px solid #eeeeee;

            span {
                display: inline-block;
                line-height: 1;

                font: {
                    size: 35px;
                    weight: 700;
                }

                ;

                margin: {
                    left: 2px;
                    right: 2px;
                }

                ;

                &.old-price {
                    color: #a2a2a2;
                    text-decoration: line-through;

                    font: {
                        weight: 600;
                        size: 20px;
                    }

                    ;

                    span {
                        text-decoration: line-through;
                        font-size: 20px !important;
                    }

                    ;
                }
            }
        }

        .buy-now-btn {
            padding: {
                left: 30px;
                right: 30px;
                top: 20px;
            }

            ;

            .default-btn {
                display: block;
                width: 100%;

                padding: {
                    top: 15px;
                    bottom: 15px;
                }

                ;
            }

            span {
                display: inline-block;
                margin-top: 20px;
                font-weight: 600;
                position: relative;
                background-color: #f5f5f5;
                border-radius: 5px;

                padding: {
                    top: 5px;
                    bottom: 5px;
                    right: 15px;
                    left: 35px;
                }

                ;

                i {
                    position: absolute;
                    left: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 20px;
                }
            }
        }

        .deals-value {
            border-top: 1px solid #eeeeee;
            display: flex;
            justify-content: space-between;
            text-align: center;
            list-style-type: none;

            margin: {
                top: 20px;
                bottom: 0;
            }

            ;

            padding: {
                left: 15px;
                top: 20px;
                right: 15px;
            }

            ;

            li {
                width: 100%;
                color: var(--optionalColor);
                font-style: 13px;

                span {
                    display: block;
                    margin-top: 6px;
                    color: var(--blackColor);

                    font: {
                        weight: 600;
                        size: 16px;
                    }

                    ;
                }

                .price-span {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                }

            }
        }

        .offer-timer {
            margin-top: 20px;
            border-top: 1px solid #eeeeee;

            padding: {
                top: 20px;
                left: 15px;
                right: 15px;
            }

            ;

            p {
                margin-bottom: 12px;
                font-style: italic;
            }

            #timer {
                div {
                    width: 80px;
                    height: 80px;
                    border-radius: 5px;
                    color: var(--blackColor);
                    background-color: #f8f8f8;

                    font: {
                        size: 25px;
                        weight: 700;
                    }

                    ;

                    margin: {
                        left: 5px;
                        right: 5px;
                    }

                    ;

                    span {
                        display: block;

                        font: {
                            size: 13px;
                            weight: 600;
                        }

                        ;
                    }

                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .widget_author {
        .content {
            img {
                margin-bottom: 20px;
                border-radius: 2px;
                width: 100px;
            }

            h4 {
                font-size: 17px;
                margin-bottom: 10px;
            }

            span {
                display: block;
                color: var(--mainColor);
            }

            .default-btn {
                margin-top: 20px;
            }
        }
    }
}

.pagination-area {
    margin-top: 25px;

    .ngx-pagination {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            color: var(--blackColor);

            margin: {
                left: 5px;
                right: 5px;
            }

            ;

            font: {
                weight: 600;
                size: var(--fontSize);
            }

            ;

            &.disabled {
                padding: 8px 17px;
                color: var(--blackColor);
                background-color: var(--whiteColor);
            }

            a {
                padding: 8px 17px;
                color: var(--blackColor);
                transition: var(--transition);
                background-color: var(--whiteColor);

                &:hover,
                &.current {
                    color: var(--whiteColor);
                    border-color: var(--mainColor);
                    background-color: var(--mainColor);
                }
            }

            &.current {
                padding: 8px 17px;
                color: var(--whiteColor);
                background-color: var(--mainColor);
            }

            &.pagination-previous {
                &::before {
                    position: relative;
                    top: -2px;
                    margin: 0;
                }

                a {
                    &::before {
                        position: relative;
                        top: -2px;
                        margin: 0;
                    }
                }
            }

            &.pagination-next {
                &::after {
                    position: relative;
                    top: -2px;
                    margin: 0;
                }

                a {
                    &::after {
                        position: relative;
                        top: -2px;
                        margin: 0;
                    }
                }
            }
        }
    }
}

.marxa-grid-sorting {
    margin-bottom: 35px;

    .result-count {
        p {
            .count {
                font-weight: 700;
                color: var(--blackColor);
            }
        }
    }

    .ordering {
        text-align: right;

        label {
            display: inline-block;
            margin-bottom: 0;
            color: var(--optionalColor);
            margin-right: 5px;
            font-weight: 500;
        }

        select {
            display: inline-block;
            width: 215px;
            background-color: var(--whiteColor);
            border: 1px solid #eeeeee;
            height: 45px;
            cursor: pointer;
            border-radius: 5px;
            padding-left: 12px;
            line-height: 45px;

            font: {
                size: var(--fontSize);
                weight: 600;
            }

            ;
        }
    }
}

.products-details-tabs {
    .nav-tabset {
        display: block;
        padding-left: 0;
        text-align: center;
        margin-bottom: 40px;
        list-style-type: none;
        border-bottom: 1px solid #dee2e6;

        .nav-tab {
            display: inline-block;
            padding-bottom: 8px;

            margin: {
                left: 15px;
                right: 15px;
            }

            ;

            span {
                padding: 0;
                border: none;
                cursor: pointer;
                position: relative;
                color: var(--optionalColor);
                background-color: transparent;
                border-bottom: 1px solid #eeeeee;

                font: {
                    size: 20px;
                    weight: 700;
                }

                ;

                &::before {
                    left: 0;
                    width: 0;
                    content: '';
                    height: 3px;
                    bottom: -12px;
                    position: absolute;
                    transition: var(--transition);
                    background-color: var(--mainColor);
                }

                &:hover {
                    color: var(--blackColor);

                    &::before {
                        width: 100%;
                    }
                }
            }

            &.active {
                span {
                    color: var(--blackColor);

                    &::before {
                        width: 100%;
                    }
                }
            }
        }
    }

    .tabs-container {
        max-width: 720px;

        margin: {
            left: auto;
            right: auto;
        }

        ;

        ul {
            padding-left: 0;
            list-style-type: none;

            margin: {
                top: 20px;
                bottom: 0;
            }

            ;

            li {
                color: var(--optionalColor);
                margin-bottom: 12px;
                position: relative;
                padding-left: 15px;
                font-weight: 500;

                &::before {
                    left: 0;
                    top: 8px;
                    width: 7px;
                    height: 7px;
                    content: '';
                    position: absolute;
                    border-radius: 50%;
                    background-color: var(--blackColor);
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .products-review-comments {
            .user-review {
                border-bottom: 1px solid #e7e7e7;
                padding: 20px 0 20px 110px;
                position: relative;

                img {
                    position: absolute;
                    left: 0;
                    top: 20px;
                    width: 90px;
                    height: 90px;
                    border-radius: 5px;
                }

                .sub-comment {
                    margin-bottom: 8px;
                    font-weight: 600;
                }

                .review-rating {
                    display: block;
                    margin-bottom: 8px;

                    .review-stars {
                        display: inline-block;

                        i {
                            font-size: 18px;
                            color: #cecfd2;
                            display: inline-block;

                            &.checked {
                                color: orange;
                            }
                        }
                    }

                    span {
                        color: var(--blackColor);
                        position: relative;
                        font-weight: 600;
                        margin-left: 5px;
                        top: -2px;
                    }
                }

                &:first-child {
                    margin-top: -20px;
                }
            }
        }

        .review-form-wrapper {
            margin-top: 30px;

            h3 {
                margin-bottom: 10px;
                font-size: 20px;
            }

            .comment-notes {
                span {
                    color: red;
                }
            }

            form {
                margin-top: 20px;

                .form-group {
                    margin-bottom: 25px;
                    text-align: left;
                }

                .rating {
                    text-align: left;
                    overflow: hidden;
                    max-width: 115px;

                    margin: {
                        top: -5px;
                        bottom: 20px;
                    }

                    ;

                    label {
                        float: right;
                        position: relative;
                        width: 23px;
                        height: 23px;
                        cursor: pointer;

                        &:not(:first-of-type) {
                            padding-right: 5px;
                        }

                        &:before {
                            transition: var(--transition);
                            content: "\2605";
                            color: #CCCCCC;
                            font-size: 27px;
                            line-height: 1;
                        }
                    }

                    input {
                        display: none;
                    }

                    input:checked~label:before,
                    &:not(:checked)>label:hover:before,
                    &:not(:checked)>label:hover~label:before {
                        color: #f6b500;
                    }
                }

                .comment-form-cookies-consent {
                    text-align: left;
                    margin-bottom: 0;

                    [type="checkbox"]:checked,
                    [type="checkbox"]:not(:checked) {
                        display: none;
                    }

                    [type="checkbox"]:checked+label,
                    [type="checkbox"]:not(:checked)+label {
                        position: relative;
                        padding-left: 28px;
                        cursor: pointer;
                        line-height: 20px;
                        display: inline-block;
                        margin-bottom: 0;
                        color: var(--optionalColor);
                        font-weight: 500;
                    }

                    [type="checkbox"]:checked+label:before,
                    [type="checkbox"]:not(:checked)+label:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 19px;
                        height: 19px;
                        transition: all 0.2s ease;
                        border: 1px solid var(--whiteColor);
                        border-radius: 3px;
                        background: var(--whiteColor);
                    }

                    [type="checkbox"]:checked+label:after,
                    [type="checkbox"]:not(:checked)+label:after {
                        content: '';
                        width: 8px;
                        height: 8px;
                        background: var(--mainColor);
                        position: absolute;
                        top: 5.5px;
                        left: 6px;
                        -webkit-transition: all 0.2s ease;
                        transition: all 0.2s ease;
                    }

                    [type="checkbox"]:not(:checked)+label:after {
                        opacity: 0;
                        -webkit-transform: scale(0);
                        transform: scale(0);
                    }

                    [type="checkbox"]:checked+label:after {
                        opacity: 1;
                        -webkit-transform: scale(1);
                        transform: scale(1);
                    }

                    [type="checkbox"]:hover+label:before {
                        border-color: var(--mainColor);
                    }

                    [type="checkbox"]:checked+label:before {
                        border-color: var(--mainColor);
                    }
                }

                button {
                    margin-top: 22px;
                    border: none;
                    display: inline-block;
                    text-align: center;
                    overflow: hidden;
                    color: var(--whiteColor);
                    background-color: var(--mainColor);
                    transition: var(--transition);
                    border-radius: 5px;
                    padding: 12px 40px;

                    font: {
                        weight: 700;
                        size: var(--fontSize);
                    }

                    ;

                    &:hover {
                        color: var(--whiteColor);
                        background-color: var(--blackColor);
                    }
                }
            }
        }
    }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {

    body {
        font-size: 13px;
    }

    p {
        font-size: 13px;
    }

    .notifier__notification {
        width: 95% !important;
    }

    .ptb-100 {
        padding: {
            top: 60px;
            bottom: 60px;
        }

        ;
    }

    .pt-100 {
        padding-top: 60px;
    }

    .pb-100 {
        padding-bottom: 60px;
    }

    .ptb-70 {
        padding: {
            top: 30px;
            bottom: 30px;
        }

        ;
    }

    .pt-70 {
        padding-top: 30px;
    }

    .pb-70 {
        padding-bottom: 30px;
    }

    .container {
        max-width: 100%;
    }

    .default-btn {
        padding: 11px 25px;
        font-size: 12px;
    }

    .section-title {
        max-width: 100%;
        text-align: center;

        margin: {
            left: 0;
            right: 0;
            bottom: 30px;
        }

        ;

        h2 {
            font-size: 19px;
        }

        &.text-start {
            text-align: center !important;

            p {
                max-width: 100%;
            }
        }
    }

    .form-control {
        padding-left: 12px;
        font-size: 13.5px;
    }

    .deals-area {
        .container-fluid {
            padding: {
                left: 15px;
                right: 15px;
            }

            ;
        }

        &.bg-black {
            &::before {
                height: 230px;
            }
        }
    }

    .deals-slides {
        .owl-theme {
            .owl-nav {
                top: 0;
                right: 0;
                margin-top: 0;
                position: relative;
                margin-bottom: 30px;

                [class*=owl-] {
                    width: 35px;
                    height: 35px;
                    font-size: 22px;
                    margin: 0 4px !important;
                }
            }
        }
    }

    .deals-slides-two {
        .owl-theme {
            .owl-nav {
                top: 0;
                right: 0;
                margin-top: 0;
                position: relative;
                margin-bottom: 30px;

                [class*=owl-] {
                    width: 35px;
                    height: 35px;
                    font-size: 22px;
                    margin: 0 4px !important;
                }
            }
        }
    }

    .deals-tabs {
        .nav-tabset {
            margin: {
                bottom: 20px;
                left: 10px;
            }

            ;

            .nav-tab {
                margin-bottom: 10px;

                span {
                    padding: 9px 16.7px;
                    font-size: 12px;
                }

                &:nth-child(1) {
                    span {
                        border-radius: 0;
                    }
                }
            }
        }
    }

    .widget-area {
        //margin-top: 40px;

        .widget {
            padding: 20px;

            .widget-title {
                font-size: 15px;
            }
        }

        .widget_categories {
            ul {
                li {
                    a {
                        font-size: 13px;
                    }
                }
            }
        }

        .widget_marxa_posts_thumb {
            .item {
                .info {
                    .title {
                        font-size: 13.5px;
                    }

                    .meta {
                        li {
                            font-size: 13px;
                            margin-right: 10px;
                            padding-left: 18px;

                            i {
                                top: 3px;
                            }
                        }
                    }
                }
            }
        }

        .widget_socials_link {
            ul {
                li {
                    a {
                        font-size: 13px;
                    }
                }
            }
        }

        .tagcloud {
            a {
                font-size: 12.5px !important;
            }
        }

        .widget_stores {
            ul {
                li {
                    font-size: 13px;

                    input {
                        top: 2px;
                    }
                }
            }
        }

        .widget_looking_for {
            ul {
                li {
                    font-size: 13px;

                    input {
                        top: 2px;
                    }
                }
            }
        }

        .widget_about_store {
            .content {
                p {
                    font-size: 13px;
                }
            }
        }

        .widget_deals_sidebar {
            padding: 20px 0;

            .price {
                span {
                    font-size: 25px;

                    &.old-price {
                        font-size: 15px;

                        span {
                            text-decoration: line-through;
                            font-size: 15px !important;
                        }
                    }
                }
            }

            .buy-now-btn span {
                i {
                    font-size: 17px;
                }
            }

            .offer-timer {
                #timer {
                    margin-top: -5px;

                    div {
                        margin-top: 10px;
                    }
                }
            }
        }

        .widget_author {
            .content {
                h4 {
                    font-size: 14px;
                }

                .default-btn {
                    margin-top: 15px;
                }
            }
        }
    }

    .page-title-area {
        padding: {
            top: 50px;
            bottom: 50px;
        }

        ;

        h1 {
            font-size: 22px;
        }
    }

    .faq-accordion {
        .panel-group {
            .panel-heading {
                .panel-title {
                    a {
                        font-size: 13px;

                        padding: {
                            top: 15px;
                            left: 15px;
                            right: 25px;
                            bottom: 15px;
                        }

                        ;

                        &::before {
                            right: 10px;
                            font-size: 15px;
                        }
                    }
                }
            }

            .panel-collapse {
                padding: 15px;
            }
        }
    }

    .pagination-area {
        margin-top: 5px;
    }

    .marxa-grid-sorting {
        text-align: center;

        .ordering {
            margin-top: 15px;
            text-align: center;

            select {
                font-size: 13px;
            }
        }
    }

    .products-details-tabs {
        .nav-tabset {
            margin-bottom: 30px;

            .nav-tab {
                margin: {
                    left: 10px;
                    right: 10px;
                }

                ;

                span {
                    font-size: 15px;
                }
            }
        }

        .tabs-container {
            max-width: 100%;

            .products-review-comments {
                .user-review {
                    padding-left: 0;

                    img {
                        position: relative;
                        left: 0;
                        top: 0;
                        margin-bottom: 15px;
                    }

                    .review-rating {
                        .review-stars {
                            i {
                                font-size: 16px;
                            }
                        }

                        span {
                            display: block !important;
                            top: 0;
                            font-size: 15px;

                            margin: {
                                left: 0;
                                top: 5px;
                            }

                            ;
                        }
                    }
                }
            }

            .review-form-wrapper {
                h3 {
                    font-size: 15px;
                }

                form {
                    button {
                        margin-top: 20px;
                        padding: 11px 30px;
                        font-size: 14px;
                    }

                    .comment-form-cookies-consent {

                        [type="checkbox"]:checked+label:before,
                        [type="checkbox"]:not(:checked)+label:before {
                            top: 4px;
                        }

                        [type="checkbox"]:checked+label:after,
                        [type="checkbox"]:not(:checked)+label:after {
                            top: 9.5px;
                        }
                    }
                }
            }
        }
    }

}

/* Min width 576px to Max width 767px */
@media only screen and (min-width: 576px) and (max-width: 767px) {

    .container {
        max-width: 540px;
    }

    .deals-area {
        .container-fluid {
            max-width: 540px;
        }
    }

}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {

    body {
        font-size: 14px;
    }

    p {
        font-size: 14px;
    }

    .ptb-100 {
        padding: {
            top: 80px;
            bottom: 80px;
        }

        ;
    }

    .pt-100 {
        padding-top: 80px;
    }

    .pb-100 {
        padding-bottom: 80px;
    }

    .ptb-70 {
        padding: {
            top: 50px;
            bottom: 50px;
        }

        ;
    }

    .pt-70 {
        padding-top: 50px;
    }

    .pb-70 {
        padding-bottom: 50px;
    }

    .container {
        max-width: 720px;
    }

    .section-title {
        h2 {
            font-size: 23px;
        }

        &.text-start {
            p {
                max-width: 550px;
            }
        }
    }

    .default-btn {
        font-size: 14px;
        padding: 12px 30px;
    }

    .form-control {
        font-size: 14px;
    }

    .deals-area {
        .container-fluid {
            max-width: 720px;

            padding: {
                left: 15px;
                right: 15px;
            }

            ;
        }

        &.bg-black {
            &::before {
                height: 216px;
            }
        }
    }

    .deals-slides {
        .owl-theme {
            .owl-nav {
                top: -75px;

                [class*=owl-] {
                    width: 35px;
                    height: 35px;
                    font-size: 24px;

                    i {
                        margin-top: 1px;
                    }

                    &.owl-next {
                        margin-left: 43px;
                    }
                }
            }
        }
    }

    .deals-slides-two {
        .owl-theme {
            .owl-nav {
                top: -75px;

                [class*=owl-] {
                    width: 35px;
                    height: 35px;
                    font-size: 24px;

                    i {
                        margin-top: 1px;
                    }

                    &.owl-next {
                        margin-left: 43px;
                    }
                }
            }
        }
    }

    .deals-tabs {
        .nav-tabset {
            .nav-tab {
                span {
                    padding: 12px 40px;
                    font-size: 14px;
                }
            }
        }
    }

    .widget-area {
        margin-top: 40px;

        .widget {
            padding: 25px;

            .widget-title {
                font-size: 18px;
            }
        }

        .widget_categories {
            ul {
                li {
                    a {
                        font-size: 14px;
                    }
                }
            }
        }

        .widget_marxa_posts_thumb {
            .item {
                .info {
                    .title {
                        font-size: 14px;
                    }

                    .meta {
                        li {
                            font-size: 13px;
                            margin-right: 10px;

                            i {
                                top: 3px;
                            }
                        }
                    }
                }
            }
        }

        .tagcloud {
            a {
                font-size: 13px !important;
            }
        }

        .widget_stores {
            ul {
                li {
                    font-size: 14px;

                    input {
                        top: 3px;
                    }
                }
            }
        }

        .widget_looking_for {
            ul {
                li {
                    font-size: 14px;

                    input {
                        top: 3px;
                    }
                }
            }
        }

        .widget_deals_sidebar {
            padding: 30px 0;
        }

        .widget_author {
            .content {
                h4 {
                    font-size: 15px;
                }

                .default-btn {
                    margin-top: 15px;
                }
            }
        }
    }

    .page-title-area {
        padding: {
            top: 70px;
            bottom: 70px;
        }

        ;

        h1 {
            font-size: 26px;
        }
    }

    .faq-accordion {
        .panel-group {
            .panel-heading {
                .panel-title {
                    a {
                        font-size: 15px;
                    }
                }
            }
        }
    }

    .pagination-area {
        margin-top: 20px;
    }

    .products-details-tabs {
        .nav-tabset {
            .nav-tab {
                span {
                    font-size: 17px;
                }
            }
        }
    }

}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {

    .container {
        max-width: 960px;
    }

    .widget-area {
        .widget {
            padding: 20px;

            .widget-title {
                font-size: 18px;
            }
        }

        .widget_categories {
            ul {
                li {
                    a {
                        font-size: 14px;
                    }
                }
            }
        }

        .widget_marxa_posts_thumb {
            .item {
                .info {
                    .title {
                        font-size: 14px;
                    }
                }
            }
        }

        .widget_stores {
            ul {
                li {
                    font-size: 14px;

                    input {
                        top: 3px;
                    }
                }
            }
        }

        .widget_looking_for {
            ul {
                li {
                    font-size: 14px;

                    input {
                        top: 3px;
                    }
                }
            }
        }

        .widget_deals_sidebar {
            padding: 30px 0;
        }

        .widget_deals_sidebar {
            .offer-timer {
                #timer {
                    margin-top: -5px;

                    div {
                        margin-top: 10px;
                    }
                }
            }
        }
    }

    .deals-area {
        &.bg-black {
            &::before {
                height: 218px;
            }
        }

        .container-fluid {
            max-width: 960px;

            padding: {
                left: 15px;
                right: 15px;
            }

            ;
        }
    }

    .page-title-area {
        padding: {
            top: 80px;
            bottom: 80px;
        }

        ;

        h1 {
            font-size: 30px;
        }
    }

    .faq-accordion {
        .panel-group {
            .panel-heading {
                .panel-title {
                    a {
                        font-size: 16px;
                    }
                }
            }
        }
    }

}

/* Min width 1200px to Max width 1355px */
@media only screen and (min-width: 1200px) and (max-width: 1355px) {

    .container {
        max-width: 1140px;
    }

    .deals-area {
        &.bg-black {
            &::before {
            }
        }
    }

    .widget-area {
        .widget_deals_sidebar {
            .offer-timer {
                #timer {
                    div {
                        width: 70px;
                        height: 70px;
                    }
                }
            }
        }
    }

}

/* Min width 1550px */
@media only screen and (min-width: 1550px) {

    .deals-area {
        .container-fluid {
            padding: {
                left: 80px;
                right: 80px;
            }

            ;
        }

        &.bg-black {
            &::before {
                height: 218px;
            }
        }
    }

}

//Custom Css

.mat-mdc-form-field {
    width: 100% !important;
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 0 !important;
    font-size: initial;
}

.notifier__notification p {
    color: #fff !important;
}

// button:disabled,
// button[disabled] {
//     background-color: #cccccc !important;
//     color: #666666 !important;
// }
.mat-mdc-option {
    min-height: 25px !important
}

// .mat-mdc-icon-button.mat-mdc-button-base {
//     width: 50px;
//     height: 35px !important;
//     padding: 5px !important;
// }

.mat-mdc-paginator-page-size-select {
    margin: 0 4px;
    width: 84px !important;
}

.ptb-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}

.form-field {
    height: 50px;
    display: block;
    width: 100%;
    border: none;
    border-radius: 5px;
    padding: 2px 0 0 15px;
    color: var(--blackColor);
    background-color: #f2f4f5;
    transition: var(--transition);

    font: {
        size: var(--fontSize);
        weight: 500;
    }

    ;

    &::placeholder {
        transition: var(--transition);
        color: var(--optionalColor);
    }

    &:focus {
        &::placeholder {
            color: transparent;
        }
    }
}

//table col$umn width helper for mat-tables
@for $index from 0 through 200 {
    $size: $index * 2;

    .table-column-#{$size} {
        // flex: 0 1 #{$size}#{"px"};
        min-width: #{$size}#{"px"};
    }
}

/* scrollbar */
::-webkit-scrollbar {
    width: 10px;
    height: 8px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.2);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255, 255, 255, 0.3);
}

// .owl-theme .owl-nav.disabled+.owl-dots {
//     position: absolute;
//     bottom: -12px;
//     width: 100%;
// }
// .owl-theme .owl-nav [class*=owl-]:hover{
//     color: #FFF;
//     font-size: 14px;
//     margin: 5px;
//     padding: 4px 7px;
//     background: #D6D6D6;
//     display: inline-block;
//     cursor: pointer;
//     border-radius: 100%;;
//     background-color:rgb(196, 65, 24);
// }
// .owl-theme .owl-nav [class*=owl-]{
//     color: #FFF;
//     font-size: 14px;
//     margin: 5px;
//     padding: 4px 7px;
//     background: #D6D6D6;
//     display: inline-block;
//     cursor: pointer;
//     border-radius: 100%;;
// }